export const environment = {
  production: true,
  apiEndpoint: 'https://turnovernotes-api-prod-cvx.azurewebsites.net/api',
  LogonAzureAPI:
    'https://100017272.auth.konycloud.com/login?provider=FWETurnaroundDev',
  AppKey: 'https://tonote-p101-cvx.vault.azure.net/secrets/ToNotesWebAppKey/e9db8c9b318e469089fcbc4551944b86',
  AppSecret: 'https://tonote-p101-cvx.vault.azure.net/secrets/ToNotesWebAppSecret/2a23b57210cf4d58b8415539a91be268',
  BaseAPI: 'https://chevronupstream-dev1.konycloud.com/services',
  PushNotificationHubPath: 'mobileturnaround-nh-prod-cvx',

  FCMSenderId: '380548788333',
  TCOInstanceId: ['2'],

  adalConfig: {
    tenant: 'chevron.onmicrosoft.com',
    clientId: 'b3deed1f-ba7b-4bf6-a5db-7b3163ad2704',
    redirectURL: 'x-msauth-tonotes://com.chevron.tonotes',
    resource: 'https://graph.windows.net',
    authority: 'https://login.windows.net/chevron.onmicrosoft.com'
  },
  scope: 'https://turnovernotes.azure.chevron.com/user_impersonation',
  schemeName: 'com.chevron.turnaroundprogress://',
  aiKey: 'b9262cc7-1178-4cdc-a414-b75ac9aa9a92',
  adtionalDbOptions: false
};
